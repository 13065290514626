<template>
  <validation-observer
    ref="offerFormObserver"
    vid="offerFormObserver"
    tag="form"
    class="offer-form"
    @keydown.enter.prevent="saveOffer"
    @submit.prevent="saveOffer"
    v-slot="{ errors }"
    data-cy="offerForm"
  >
    <!-- references -->
    <section class="offer-form__header">
      <c-input
        name="sellerCustomId"
        :placeholder="$t('ctx.offers.variantOfferForm.references.sellerCustomIdPlaceholder')"
        v-model="editingOffer.sellerCustomId"
        :label="$t('ctx.offers.variantOfferForm.references.sellerCustomIdLabel')"
        :disabled="!isNewOffer"
        rules="required"
        :formErrors="errors.sellerCustomId"
        @input="updateOfferId"
        data-test="seller-custom-id"
        data-cy="offerFormIdField"
      />
      <c-input
        name="offerTechnicalInformation"
        v-model="editingOffer.offerTechnicalInformation"
        :label="$t('ctx.offers.variantOfferForm.references.offerTechnicalInformationLabel')"
        rules="max:255"
        :formErrors="errors.offerTechnicalInformation"
        data-test="technical-information"
        data-cy="offerFormTechnicalInformationField"
      />
    </section>
    <!-- offer-only-tc,  offer-allowed-without-stock-->
    <section class="offer-form__section">
      <h1>{{ $t('ctx.offers.variantOfferForm.characteristics.title') }}</h1>
      <div class="offer-form__row">
        <label class="offer-form__checkbox-label"
          ><input
            type="checkbox"
            name="offer-only-tc"
            v-model="editingOffer.isTechExclusive"
            data-test="only-tc-checkbox"
          />
          <span>{{ $t('ctx.offers.variantOfferForm.characteristics.techniciansOffer') }}</span>
        </label>
        <div class="offer-form__allowed-without-stock" data-test-allowed-without-stock>
          <label class="offer-form__checkbox-label"
            ><input
              type="checkbox"
              name="offer-allowed-without-stock"
              v-model="editingOffer.allowedWithoutStock"
              data-test-allowed-without-stock-checkbox
            />
            <span>{{ $t('ctx.offers.variantOfferForm.characteristics.offerWithoutStock') }}</span>
          </label>
        </div>
      </div>
      <div class="offer-form__row" data-test-price-type>
        <c-input-select
          id="priceType"
          :label="$t('ctx.offers.variantOfferForm.characteristics.priceTypeLabel')"
          name="priceType"
          :placeholder="$t('ctx.offers.variantOfferForm.characteristics.priceTypePlaceholder')"
          rules="required"
          :formErrors="errors.priceType"
          :options="availablePriceTypes"
          v-model="editingOffer.priceType"
          data-cy="offerFormPriceTypeField"
          data-test="price-type-input"
        />
      </div>
      <div class="offer-form__row">
        <c-input-select
          id="offer-type"
          :label="$t('ctx.offers.variantOfferForm.characteristics.offerTypeLabel')"
          name="offerType"
          :options="offerTypes"
          v-model="editingOffer.offerType"
          data-cy="offerFormOfferTypeField"
          data-test="offer-type-input"
        />
      </div>
      <div class="offer-form__row">
        <c-input-date
          :label="$t('ctx.offers.variantOfferForm.characteristics.validityDatesLabel')"
          :formErrors="computeInputDateProps(errors.validityStartDate, errors.validityEndDate)"
          :name="computeInputDateProps('validityStartDate', 'validityEndDate')"
          :rules="computeInputDateProps('required', 'required_if:shippingEndDate|fromToday|after:@validityStartDate')"
          v-model="validityDates"
          data-cy="validityDates"
        />
      </div>
    </section>
    <section v-if="isPrestaServiceEnabled" class="offer-form__section">
      <h1>{{ $t('ctx.offers.variantOfferForm.restrictedSales.title') }}</h1>
      <div class="offer-form__row">
        <validation-provider
          ref="restrictedSalesMin"
          vid="restrictedSalesMin"
          name="restrictedSalesMin"
          :rules="validityRestrictedSalesMin"
        >
          <IdfStandardInputs
            :label="$t('ctx.offers.variantOfferForm.restrictedSales.minLabel')"
            :error="errors.restrictedSalesMin && errors.restrictedSalesMin.length > 0"
            :message="errors.restrictedSalesMin && errors.restrictedSalesMin[0]"
          >
            <div class="offer-form__input-restricted-sales-min">
              <IdfInput
                v-model="editingOffer.restrictedSalesMin"
                kind="number"
                step=".01"
                :error="errors.restrictedSalesMin && errors.restrictedSalesMin.length > 0"
                name="restrictedSalesMin"
                data-test="restrictedSalesMin"
              />
            </div>
          </IdfStandardInputs>
        </validation-provider>
      </div>
      <div class="offer-form__row">
        <validation-provider
          ref="restrictedSalesMax"
          vid="restrictedSalesMax"
          name="restrictedSalesMax"
          :rules="validityRestrictedSalesMax"
        >
          <IdfStandardInputs
            :label="$t('ctx.offers.variantOfferForm.restrictedSales.maxLabel')"
            :error="errors.restrictedSalesMax && errors.restrictedSalesMax.length > 0"
            :message="errors.restrictedSalesMax && errors.restrictedSalesMax[0]"
          >
            <div class="offer-form__input-restricted-sales-max">
              <IdfInput
                v-model="editingOffer.restrictedSalesMax"
                kind="number"
                step=".01"
                :error="errors.restrictedSalesMax && errors.restrictedSalesMax.length > 0"
                name="restrictedSalesMax"
                data-test="restrictedSalesMax"
              />
            </div>
          </IdfStandardInputs>
        </validation-provider>
      </div>
    </section>
    <!-- shipping dates, shipping -->
    <section class="offer-form__section">
      <h1>{{ $t('ctx.offers.variantOfferForm.shipping.title') }}</h1>
      <div class="offer-form__row">
        <c-input-date
          :label="productShippingStartDateLabel"
          :formErrors="computeInputDateProps(errors.shippingStartDate, errors.shippingEndDate)"
          :name="computeInputDateProps('shippingStartDate', 'shippingEndDate')"
          :rules="
            computeInputDateProps(
              'after:@validityStartDate',
              'fromToday|after:@shippingStartDate|after:@validityEndDate',
            )
          "
          v-model="shippingDates"
          data-cy="shippingDates"
        />
      </div>
      <div class="offer-form__row">
        <validation-provider
          tag="label"
          class="offer-form__label"
          vid="shippingMethodList"
          name="shippingMethodList"
          ref="shippingMethodList"
          data-test-offer-form-shipping-way
          rules="required"
          >{{ productShippingLabel }}
          <small v-html="$t('ctx.offers.variantOfferForm.shipping.validationHelp')" />
          <select
            class="offer-form__shipping-ways"
            name="shippingMethodList"
            v-model="editingOffer.shippingMethodList"
            multiple
            data-cy="offerFormShippingWayField"
          >
            <option
              v-for="logisticOffer in logisticOffers"
              :key="logisticOffer.reference"
              :value="logisticOffer.reference"
              :data-test="'shipping-way__' + logisticOffer.reference + '_option'"
            >
              {{ logisticOffer.deliveryModeBusinessName }} ({{ logisticOffer.reference }})
            </option>
          </select>
        </validation-provider>
        <PErrorMessage v-for="error in errors.shippingMethodList" :key="error">{{
          formatErrorMessage(translateError(error, productIsService))
        }}</PErrorMessage>
      </div>
    </section>
    <!-- price -->
    <section class="offer-form__section">
      <h1>{{ $t('ctx.offers.variantOfferForm.price.title') }}</h1>
      <div class="offer-form__row">
        <validation-provider
          ref="measureUnitPrice"
          vid="measureUnitPrice"
          name="measureUnitPrice"
          :rules="validityPrice"
        >
          <IdfStandardInputs
            :label="$t('ctx.offers.variantOfferForm.price.measureUnitPriceLabel')"
            :error="errors.measureUnitPrice && errors.measureUnitPrice.length > 0"
            :message="errors.measureUnitPrice && errors.measureUnitPrice[0]"
          >
            <div class="offer-form__input-price">
              <IdfInput
                v-model="editingOffer.measureUnitPrice"
                kind="number"
                step=".00001"
                :error="errors.measureUnitPrice && errors.measureUnitPrice.length > 0"
                name="measureUnitPrice"
                data-test="measure-unit-price"
              />
              <span class="offer-form__content-text-unit" data-test-measure-unit>
                {{ $t('ctx.offers.variantOfferForm.price.euroOn') }}{{ sellingUnit }}
              </span>
              <template v-if="isMeasurementPriceValid">
                {{ $t('ctx.offers.variantOfferForm.price.either') }}
                <strong data-test="price-conversion">
                  {{ formatEuros(unitPriceFromMeasurementPrice) }}{{ $t('ctx.offers.variantOfferForm.price.onUnit') }}
                </strong>
              </template>
            </div>
          </IdfStandardInputs>
        </validation-provider>
      </div>
    </section>
    <!-- min quantity, max quantity, interval -->
    <section class="offer-form__section" data-test="min-max-step-block">
      <h1>{{ $t('ctx.offers.variantOfferForm.quantity.title') }}</h1>
      <div class="offer-form__quantity">
        <div class="offer-form__quantity-inputs">
          <c-input
            class="offer-form__interval-name"
            name="intervalName"
            :label="$t('ctx.offers.variantOfferForm.quantity.intervalNameLabel')"
            :placeholder="$t('ctx.offers.variantOfferForm.quantity.intervalNamePlaceholder')"
            v-model="editingOffer.intervalName"
            data-cy="offerFormIntervalName"
          />
          <c-input-quantity
            v-model="editingOffer.quantityStep"
            name="quantityStep"
            :formErrors="errors.quantityStep"
            :rules="quantityStepRules"
            :quantityWithUnit="computeQuantityInUnit(editingOffer.quantityStep)"
            data-test="step-input-quantity"
            data-cy="offerFormQuantityStepField"
          />
          <c-input-quantity
            v-model="editingOffer.quantityMin"
            :label="$t('ctx.offers.variantOfferForm.quantity.quantityMinLabel')"
            name="quantityMin"
            :formErrors="errors.quantityMin"
            :rules="quantityMinRules"
            :quantityWithUnit="computeQuantityInUnit(editingOffer.quantityMin)"
            data-test="min-input-quantity"
            data-cy="offerFormQuantityMinField"
          />
          <c-input-quantity
            v-model="editingOffer.quantityMax"
            :label="$t('ctx.offers.variantOfferForm.quantity.quantityMaxLabel')"
            name="quantityMax"
            :formErrors="errors.quantityMax"
            :rules="quantityMaxRules"
            :quantityWithUnit="computeQuantityInUnit(editingOffer.quantityMax)"
            data-test="max-input-quantity"
            data-cy="offerFormQuantityMaxField"
          />
        </div>
        <p-quantity-information
          class="offer-form__quantity-explained"
          :min="+editingOffer.quantityMin"
          :max="+editingOffer.quantityMax"
          :step="+editingOffer.quantityStep"
          :measureQuantity="measureQuantity"
          :unit="measureUnit"
        />
      </div>
    </section>
    <!-- segment -->
    <section v-if="!isNewOffer && hasSegment" class="offer-form__section" data-test="segment-block">
      <h1>{{ $t('ctx.offers.variantOfferForm.segment.title') }}</h1>
      <p data-test="segment-list">{{ offerSegments }}</p>
    </section>
    <!-- description -->
    <section class="offer-form__section" data-test="description-block">
      <h1>{{ $t('ctx.offers.variantOfferForm.description.title') }}</h1>
      <textarea
        class="offer-form__control"
        name="offerDescription"
        rows="3"
        maxlength="180"
        v-model="editingOffer.description"
        data-test="offer-description-input"
        data-cy="offerFormDescriptionField"
      ></textarea>
      <p class="offer-description__length-msg" data-test="offer-description-char-length">
        {{ remainingCharacters }} {{ pluralize('caractère', remainingCharacters) }}
        {{ pluralize('restant', remainingCharacters) }}
      </p>
    </section>
    <!-- submit -->
    <section class="offer-form__button-section">
      <div v-if="!isSending" class="offer-form__btn-group">
        <IdfButton
          kind="link"
          icon-left="close"
          @click.prevent="cancelOfferForm"
          data-cancel-button
          data-cy="offerFormCancelButton"
          >{{ $t('ctx.offers.variantOfferForm.submit.cancel') }}</IdfButton
        >
        <IdfButton data-submit-button data-cy="offerFormSaveButton">
          {{ $t('ctx.offers.variantOfferForm.submit.save') }}
        </IdfButton>
      </div>
      <div v-else>
        <span>{{ $t('ctx.offers.variantOfferForm.submit.loading') }}</span>
      </div>
    </section>
  </validation-observer>
</template>

<script>
import Big from 'big.js/big'
import { ValidationProvider, ValidationObserver } from 'vee-validate-last'
import { computed, defineComponent, inject, reactive, ref, watch, onMounted } from 'vue'
import { pluralize, formatMeasureUnit, formatEuros } from '@invivodf/common/src/core/l10n'
import { useLogisticOfferListSymbol } from '@invivodf/common/src/contexts/logistic-offer/application/composables/dependency-injection'
import { IdfButton, IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { usePrestaService } from '@invivodf/common/src/composables/usePrestaService'
import { use18n } from '@invivodf/locales/src/use18n'
import { useStore } from '@/composables/useStore'
import { formatErrorMessage, translateError } from '@/filters'
import { CInput, CInputQuantity, CInputSelect, CInputDate } from './inputs'
import { defaultPriceTypes, disallowedPriceTypes } from '../../../services/price-type.const'
import measureUnit, { unitLabel } from '../../../filters/unit/measure.filter'
import PQuantityInformation from '../../../presenters/PQuantityInformation/PQuantityInformation.vue'
import defaultOfferTypes from '../../../services/offer-type.const'
import PErrorMessage from '../../../presenters/PErrorMessage.vue'
import { offerService } from '../../../services'

const DESCRIPTION_MAX_CHARS = 180

function snakeToCamel(str) {
  return str.replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''))
}
function transformAPIError(errors) {
  return errors.reduce((acc, err) => ({ ...acc, [snakeToCamel(err.field)]: err.messages }), {})
}
function getAllowedProps(defaultList, disallowedMap, propName, testValue) {
  return defaultList.filter((e) => !disallowedMap[e[propName]].has(testValue))
}

export default defineComponent({
  name: 'CVariantOfferForm',
  props: {
    variantId: String,
    measureQuantity: Number,
    measureUnit: String,
    isSoldAsUnit: Boolean,
    productKind: String,
    offer: {
      type: Object,
      default: () => ({}),
    },
    storeNamespace: {
      type: String,
      default: () => 'variantOffers',
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    PQuantityInformation,
    CInput,
    CInputQuantity,
    CInputSelect,
    CInputDate,
    IdfButton,
    IdfStandardInputs,
    IdfInput,
    PErrorMessage,
  },
  setup(props, context) {
    const i18n = use18n()
    const store = useStore()
    const isPrestaServiceEnabled = ref(false)
    const offerFormObserver = ref(null)
    const editingOffer = computed(() => ({
      id: props.offer.id || '',
      measureUnitPrice: props.offer.measureUnitPrice,
      variantInvivoId: props.variantId,
      sellerCustomId: props.offer.sellerCustomId || '',
      validityStartDate: props.offer.validityStartDate
        ? props.offer.validityStartDate.split('T')[0]
        : new Date().toISOString().split('T')[0],
      validityEndDate: props.offer.validityEndDate && props.offer.validityEndDate.split('T')[0],
      shippingStartDate: props.offer.shippingStartDate && props.offer.shippingStartDate.split('T')[0],
      shippingEndDate: props.offer.shippingEndDate && props.offer.shippingEndDate.split('T')[0],
      shippingMethodList: props.offer.shippingMethodList || [],
      priceType: props.offer.priceType || '',
      isTechExclusive: props.offer.isTechExclusive || false,
      quantityMin: props.offer.quantityMin || 1,
      quantityMax: props.offer.quantityMax || undefined,
      quantityStep: props.offer.quantityStep || 1,
      intervalName: props.offer.intervalName,
      segmentList: props.offer.segmentList || [],
      offerType: props.offer.offerType || '',
      description: props.offer.description,
      allowedWithoutStock: props.offer.allowedWithoutStock || false,
      offerTechnicalInformation: props.offer.offerTechnicalInformation,
      measureUnit: props.measureUnit,
      paymentDueDateReferences: props.offer.paymentDueDateReferences,
      restrictedSalesMin: props.offer.restrictedSalesMin,
      restrictedSalesMax: props.offer.restrictedSalesMax,
    }))
    const isSending = ref(false)
    const validityDates = reactive({
      startDate: editingOffer.value.validityStartDate,
      endDate: editingOffer.value.validityEndDate,
    })
    const shippingDates = reactive({
      startDate: editingOffer.value.shippingStartDate,
      endDate: editingOffer.value.shippingEndDate,
    })
    const cooperativeId = computed(() => store.getters.cooperativeId)
    const useLogisticOfferList = inject(useLogisticOfferListSymbol)
    const { loadLogisticOfferList, logisticOfferList: logisticOffers } = useLogisticOfferList()
    const isNewOffer = computed(() => !(props.offer && props.offer.id))
    const isMeasurementPriceValid = computed(() => !!props.offer.measurementPrice && !!props.measureQuantity)
    const unitPriceFromMeasurementPrice = computed(() => props.offer.measurementPrice * props.measureQuantity)
    const sellingUnit = computed(() => (props.isSoldAsUnit ? unitLabel.unit : measureUnit(props.measureUnit)))
    const validityPrice = 'required|min_value:0|decimal:5'
    const validityRestrictedSalesMin = 'min_value:0|decimal:2|strictlylessThan:restrictedSalesMax'
    const validityRestrictedSalesMax = 'min_value:0|decimal:2|strictlygreaterThan:restrictedSalesMin'
    const prestaServicePriceType = ref([])
    const priceTypes = computed(() => (isPrestaServiceEnabled.value ? prestaServicePriceType.value : defaultPriceTypes))
    const availablePriceTypes = computed(() => {
      const allowedPriceTypes = getAllowedProps(priceTypes.value, disallowedPriceTypes, 'name', props.productKind)
      const formattedPriceTypes = {}
      allowedPriceTypes.forEach((priceType) => {
        if (priceType.name === 'indicativePrice' && !isPrestaServiceEnabled.value) return
        formattedPriceTypes[priceType.name] = priceType.title
      })
      return formattedPriceTypes
    })
    const productShippingLabel = computed(() =>
      props.productKind !== 'services'
        ? i18n.t('ctx.offers.variantOfferForm.productShippingLabel.shippingMode')
        : i18n.t('ctx.offers.variantOfferForm.productShippingLabel.interventionType'),
    )
    const productShippingStartDateLabel = computed(() =>
      props.productKind !== 'services'
        ? i18n.t('ctx.offers.variantOfferForm.productShippingStartDateLabel.shippingPossible')
        : i18n.t('ctx.offers.variantOfferForm.productShippingStartDateLabel.interventionPeriod'),
    )
    const productIsService = computed(() => props.productKind === 'services')
    const quantityMinRules = 'required|integer|min_value:1|lessThan:quantityMax|multipleOf:quantityStep'
    const quantityStepRules = 'required|integer|min_value:1|lessThan:quantityMax'
    const quantityMaxRules = 'integer|greaterThan:quantityMin|multipleOf:quantityStep'
    const offerSegments = computed(() => {
      const segments = props.offer.segmentList || []
      return segments.join(', ')
    })
    const hasSegment = computed(() => props.offer.segmentList && props.offer.segmentList.length > 0)
    const remainingCharacters = computed(() =>
      editingOffer.value.description
        ? Math.max(DESCRIPTION_MAX_CHARS - editingOffer.value.description.length, 0)
        : DESCRIPTION_MAX_CHARS,
    )
    function getErrorMessage(method) {
      return method === 'create'
        ? i18n.t('ctx.offers.variantOfferForm.errorMessage.onCreate')
        : i18n.t('ctx.offers.variantOfferForm.errorMessage.onUpdate')
    }
    function cancelOfferForm() {
      context.emit('cancel')
    }
    function updateOfferId() {
      editingOffer.value.id = `${cooperativeId.value}:${props.variantId}:${editingOffer.value.sellerCustomId}`
    }
    async function saveOffer() {
      const method = isNewOffer.value ? 'create' : 'update'
      const formIsValid = await offerFormObserver.value.validate()

      isSending.value = formIsValid
      if (formIsValid) {
        try {
          const actionName = method === 'create' ? 'addOffer' : 'updateOffer'
          const offer = await store.dispatch(`${props.storeNamespace}/upsertOffer`, {
            offer: editingOffer.value,
            logisticOffers: logisticOffers.value.filter((logisticOffer) =>
              editingOffer.value.shippingMethodList.includes(logisticOffer.reference),
            ),
            actionName,
          })
          context.emit('success', offer)
        } catch (errors) {
          const { response: errorData } = errors || {}
          if (errorData && errorData.status !== 400) {
            store.dispatch('setToast', { type: 'danger', message: getErrorMessage(method) })
            context.emit('fail')
          }
          if (errorData && errorData.status === 400) {
            const apiErrors = transformAPIError(errorData.data.errors)

            offerFormObserver.value.setErrors(apiErrors)
          }
        } finally {
          isSending.value = false
        }
      } else {
        setTimeout(() => {
          const errors = Object.entries(offerFormObserver.value.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error.value.length)
          if (offerFormObserver.value.refs[errors[0].key].$el.scrollIntoView) {
            offerFormObserver.value.refs[errors[0].key].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          }
        }, 100)
      }
    }
    function computeInputDateProps(startDateProp, endDateProp) {
      return {
        startDate: startDateProp,
        endDate: endDateProp,
      }
    }
    function computeQuantityInUnit(quantity) {
      const maximumFractionDigits = 5
      return (
        quantity &&
        props.measureQuantity &&
        formatMeasureUnit(
          Big(quantity).times(props.measureQuantity),
          measureUnit(props.measureUnit),
          maximumFractionDigits,
        )
      )
    }
    async function initPrestaService() {
      isPrestaServiceEnabled.value = (await usePrestaService()).isPrestaServiceEnabled

      if (!isPrestaServiceEnabled.value) return

      prestaServicePriceType.value = await offerService
        .getPrestaServicePriceType(cooperativeId.value, props.variantId)
        .then((prestaServicePriceTypes) =>
          defaultPriceTypes.filter(({ name }) => prestaServicePriceTypes.includes(name)),
        )
    }

    watch(validityDates, (newValidityDates) => {
      editingOffer.value.validityStartDate = newValidityDates.startDate
      editingOffer.value.validityEndDate = newValidityDates.endDate
    })
    watch(shippingDates, (newShippingDates) => {
      editingOffer.value.shippingStartDate = newShippingDates.startDate
      editingOffer.value.shippingEndDate = newShippingDates.endDate
    })

    onMounted(() => {
      try {
        initPrestaService()
        loadLogisticOfferList()
      } catch (e) {
        store.dispatch('setToast', { type: 'danger', message: e })
        context.emit('fail')
      }
    })

    return {
      availablePriceTypes,
      computeQuantityInUnit,
      cooperativeId,
      editingOffer,
      hasSegment,
      isMeasurementPriceValid,
      isNewOffer,
      isSending,
      loadLogisticOfferList,
      logisticOffers,
      offerSegments,
      offerTypes: defaultOfferTypes,
      productIsService,
      productShippingLabel,
      productShippingStartDateLabel,
      quantityMaxRules,
      quantityMinRules,
      quantityStepRules,
      remainingCharacters,
      sellingUnit,
      shippingDates,
      unitPriceFromMeasurementPrice,
      validityDates,
      validityPrice,
      validityRestrictedSalesMin,
      validityRestrictedSalesMax,
      cancelOfferForm,
      computeInputDateProps,
      pluralize,
      saveOffer,
      updateOfferId,
      offerFormObserver,
      formatEuros,
      translateError,
      formatErrorMessage,
      isPrestaServiceEnabled,
    }
  },
})
</script>

<style scoped lang="scss">
@import '../../../lib/_variables.scss';
.offer-form {
  font-size: 1.6rem;
  margin: -1.6rem;
  h1 {
    font-size: 2.8rem;
  }
}
.label {
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 0;
  white-space: nowrap;
}
.offer-form__control {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: var(--idf-spacing-2) var(--idf-spacing-3);
  width: 100%;
  border-radius: var(--idf-border-radius-s);
  border-width: 1px;
  margin-right: 1rem;
  background: var(--idf-color-neutral-100);
  &--error {
    border-color: var(--idf-color-error-400);
  }
}
.offer-form__content-text-unit {
  font-weight: bold;
}
.offer-form__price-conjunction {
  margin: 0 1rem;
}
.offer-form__row {
  margin-top: 20px;
  align-items: center;
}
.offer-form .col-md-4 {
  display: flex;
  align-items: center;
}
.offer-form__input-price,
.offer-form__input-restricted-sales-min,
.offer-form__input-restricted-sales-max {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  gap: var(--idf-spacing-3);
}
.offer-form__control.offer-form__input-max {
  display: flex;
  width: 100px;
  margin-right: 10px;
  text-align: right;
}
.offer-form__icon-warning {
  width: 1em;
  padding-bottom: 4px;
  margin-right: 5px;
}
.offer-form__content-group {
  display: flex;
  justify-content: space-between;
}
.offer-form__date-group {
  max-width: 150px;
  display: flex;
  align-items: center;
}
.offer-form__date-prefix {
  align-items: center;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
}
.offer-form_only-tc,
.offer-form__allowed-without-stock {
  display: inline-flex;
  position: relative;
  list-style: none;
  padding: 0 0 0 25px;
  cursor: pointer;
  margin-right: 2.2rem;
}
.offer-form__label {
  width: 50%;
}
.offer-form__shipping-ways {
  height: 4em;
  border-radius: var(--idf-border-radius-s);
  display: block;
  margin-top: var(--idf-spacing-2);
  padding: var(--idf-spacing-2) var(--idf-spacing-3);
  box-sizing: content-box;
}
.offer-form__btn-form {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.offer-form__section {
  padding: 2rem 0;
  margin: 0 3rem;
  &:not(:first-of-type):not(:last-of-type):not(:nth-child(2)) {
    border-top: 2px solid var(--idf-color-neutral-200);
  }
}
.offer-form__header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
  padding: 2rem 3rem;
  border-bottom: 1px solid var(--idf-color-neutral-200);
  .col {
    display: flex;
    flex-direction: column;
  }
}
.offer-form__date-input {
  margin-right: 1.1rem;
}
.offer-form__button-section {
  position: sticky;
  right: 0;
  bottom: 0;
  padding: 1.5rem 0;
  background-color: white;
  box-shadow: 1px -1px 4px var(--idf-color-neutral-500);
  padding-right: 4.5rem;
}
.offer-form__btn-group {
  display: flex;
  justify-content: right;
  gap: var(--idf-spacing-2);
}
.offer-form__allowed-without-stock--disabled {
  opacity: 0.4;
  pointer-events: none;
}
.offer-description__length-msg {
  padding-top: 0.5rem;
  font-size: 1.4rem;
  color: var(--idf-color-neutral-500);
}
.offer-form__quantity {
  display: flex;
  input {
    width: 10rem;
  }
}
.offer-form__quantity-inputs {
  width: 70%;
}
.offer-form__checkbox-label {
  display: inline-flex;
  align-items: baseline;
  position: relative;
  list-style: none;
  padding: 0 0 0 25px;
  cursor: pointer;
  margin-right: 2.2rem;
  margin-bottom: 0;
}
.offer-form__checkbox-label > input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span,
  &:checked ~ span:after {
    font-weight: bold;
    color: var(--idf-color-main-400);
    display: inline-block;
    border-color: var(--idf-color-main-400);
  }
}
.offer-form__checkbox-label span {
  margin-bottom: 0;
  position: relative;
  line-height: 1.6rem;
  &:before {
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    border: 1px solid;
    border-radius: 2px;
    display: block;
    left: -25px;
  }
  &:after {
    left: -18px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid var(--idf-color-main-400);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }
}
</style>
